import axios from 'axios';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaDashcube, FaPrint, FaRecycle, FaTrashAlt } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, Row } from 'react-bootstrap';
import   '../Customcss/saleEntry.css';
import { Box } from '@mui/system';
import PrintSaleInvoice from './PrintSaleInvoice';
import AddUserModel from './AddUserModel';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import { Alert, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateContext } from '../Context/ContextProvider';

  
function FreshSaleEntry() {

const[decideCustomer,setDecideCustomer]=useState(localStorage.getItem('creditcash')?localStorage.getItem('creditcash'):'1');

const {type,userId,weburl}=useStateContext();
  const [isColHidden, setIsColHidden] = useState(false);
     const  {id}  = useParams();
     const [salesman,setsalesman]=useState([]);
     const [sectors,setsectors]=useState([]);
     const [results,setresutls]=useState([]);
     const [item, setItem] = useState([]);
const [selectedOptionsector, setSelectedOptionsector] = useState(0);//selected means default
const [selectedOptionshop, setSelectedOptionshop] = useState(0);
const [subsectors, setsubsectors] = useState(null);
const [shop, setshop] = useState([]);
 const [selectedOption, setSelectedOption] = useState(null);
 const [totalQty, setTotalQty] = useState(0);
 const [totalCtn, setTotalCtn] = useState(0);
 const [totalPcs, setTotalPcs] = useState(0);
 const [totalUnitSalePrice, setTotalUnitSalePrice] = useState(0);
 const [grandTotal, setGrandTotal] = useState(0);
 const [totalWeight, setTotalWeight] = useState(0);
 const [autoInvoices, setAutoInvoices] = useState(0);
 const [pBlanace, setPbalance] = useState(0);
 const [shopId, setShopId] = useState(0);
 const [salesmanId, setSalesmanId] = useState(0);
  
 const inputRef = useRef(null);
 const inputRefCash = useRef(null);
 const autocompleteRef = useRef(null);

 const [entercash,setEntercash]=useState(0);
 
 const [discount,setDsicount]=useState(0);
 
 const [ppadvance,setPPadvance]=useState(0);
 const [recovery,setRecovery]=useState(0);
 const [remaining,setRemaining]=useState(0);
 const [balance,setBalance]=useState(0);

 const [manualdate, setManualdate] = useState( getToday());

 const [salesTax, setSalesTax] = useState(localStorage.getItem('salesTax')!==null?localStorage.getItem('salesTax'):0);//its consider as tax percent value
 const [salesTaxAmount, setSalesTaxAmount] = useState(0);
 const [shipping, setShipping] = useState(0);
 const [extra_discount, setextra_discount] = useState(0);
 const [fix_discount, setfix_discount] = useState(0);

 const [printData, setPrintData] = useState([]);
 const [isPrint,setIsPrint]=useState(false);
 const [loading,setLoading]=useState(false);
 const [holdLoading,setHoldLoading]=useState(false);
 const [isAddUser,setIsAddUser]=useState(false);
 const [isCheck, setIsCheck] = useState(localStorage.getItem('radio')?localStorage.getItem('radio'):'1');
const [holdData,setHoldData]=useState(localStorage.getItem('customerData')?JSON.parse(localStorage.getItem('customerData')):[]);
const [holdIndex,setHoldIndex]=useState('');

const handleShipping=(e)=>{
if(e.target.value===''){
  setShipping(0);
}else{

  setShipping(e.target.value);
}

}



const handleKeyPress = (e) => {
  if (e.key === 'Enter') {

if(e.target.value!==''){
  


    //setSalesTax(e.target.value);
    localStorage.setItem('salesTax', e.target.value);
    toast.success('updating salestax percent!', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
    }
    else{
      //setSalesTax(0);
      localStorage.setItem('salesTax', 0);
      toast.success('updating salestax percent to default!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }
};
const setSalesTaxInLocalStorage = (e) => {
 

  if(e.target.value !=='' ){
    setSalesTax(e.target.value);
  }else{
    setSalesTax(0);

  }
   
    
    
};
const HandleResetValues=(e)=>{
e.preventDefault();
setItem([]);

}
const handleHoldBack=(index)=>{
setItem(holdData[index]['items']);
setSelectedOptionsector(holdData[index]['name']['sector']);
setSelectedOptionshop(holdData[index]['name']['subsector']);
setShopId(holdData[index]['name']['ppcell']);
setHoldIndex(index);
}
const handleDeleteOnHold=(e,index)=>{
  e.preventDefault();
  const customerData = [...holdData];
  customerData.splice(index, 1);
  setHoldData(customerData);
  localStorage.setItem('customerData', JSON.stringify(customerData));

}
const handleOnHold=()=>{


setHoldLoading(true);
  const customer={
    sector:selectedOptionsector,
    subsector:selectedOptionshop,
    ppcell:shopId,
   
  }
  const newCustomer = {
    name: customer,
    items: item,
  };
  if (Object.keys(newCustomer.items).length !== 0) {
  // Retrieve the existing data from localStorage
  const existingDataString = localStorage.getItem('customerData');

  // Parse the JSON string into a JavaScript array of objects (or create an empty array if it doesn't exist)
  const existingData = existingDataString ? JSON.parse(existingDataString) : [];

  // Push the new customer object to the existing array
  existingData.push(newCustomer);

  // Convert the updated array of objects back to a JSON string
  const updatedDataString = JSON.stringify(existingData);

  // Save the updated JSON string back to localStorage
  
    localStorage.setItem('customerData', updatedDataString);
 


  const holddata=localStorage.getItem('customerData');
  setHoldData(JSON.parse(holddata));
  toast.success('Invoice on hold!', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
  }else{
    toast.warning('Invoice items not found!', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }
  
  setHoldLoading(false);

}

  const handleRadioChange = (event) => {
    setIsCheck(event.target.value);
    localStorage.setItem('radio',event.target.value);
    toast.success('Your Price Mode Is Switching!', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  };
  const handleCreditCash = (event) => {
    setDecideCustomer(event.target.value);
    localStorage.setItem('creditcash',event.target.value);
   
    
    toast.success('Customer Type Changed!', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
     
  };
 function getToday() {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  // add leading zero to month and day if they're less than 10
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;
  return `${year}-${month}-${day}`; // return date in YYYY-MM-DD format
}



 const handleAddNewUser= (childData) => {
  const data={
    name:childData.name,
    phone:childData.phone,
    sector:selectedOptionsector,
    subsector:selectedOptionshop
    
     }
  axios.post('/api/add/customer/to/db',data).then(res=>{
  //console.log(res.data)
if(res.data===201){
  
  toast.error('Phone Number Already Exist!', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
}else{

  toast.success('New Customer Added!', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
setshop(res.data.customer);
setShopId(res.data.defaultshop.user_id);
//setShopLoad(true);

}




  });




  setIsAddUser(false);
};

useEffect(()=>{
  
if(selectedOptionshop){


  axios.get(`/api/getshop/${selectedOptionshop}`)
  .then(response => {
    

 

    const sortedShpos = [...response.data.shop].sort((a, b) => a.name.localeCompare(b.name));
    setshop(sortedShpos);
   
    setShopId(response.data.shop[0]['user_id']);//its mean default shop
   
 
  
  })
  .catch(error => {
    console.error(error);
  });
}
},[selectedOptionshop])

const handleModelClose=(data)=>{

setIsAddUser(data);

}





const handleSaveReciept=useCallback((e)=>{


e.preventDefault();
setLoading(true);

const data={
 letter:item,
 sector:selectedOptionsector,
 subsector:selectedOptionshop,
 ppcell:shopId,
 manualdate:manualdate,
 recovery:recovery,
 ppadvance:ppadvance,
 salesmanid:type==='Salesman'?userId:salesmanId,
 sales_tex_percent:salesTax,
 shipping:shipping,
 supergrandtotal:grandTotal,
 discount:discount,
 extra_discount:extra_discount,
 salesTaxAmount:salesTaxAmount,
 entercash:entercash
 
  }
  
  
 if(data.letter.length>0 && data.ppcell!=='' && data.manualdate? data.manualdate.length>0:0){

 
    axios.post('/api/post/reciept/entrysale',data).then(res=>{
  
     
      const printDatas={
letter:res.data.cart,
dates:res.data.dates,
salestax:res.data.salestax,
discount:res.data.discount,
grandtotal:res.data.grandtotal,
invoiceno:res.data.invoiceno,
remainingbill:res.data.remainingbill,
customer:res.data.customer,
customeraddress:res.data.customeraddress,
salesman:res.data.salesman,
payment:res.data.payment,
totalpay:res.data.totalpay,
pbalance:res.data.pbalance,
recovery:res.data.recovery,
printingtime:res.data.printingtime,
manualdate:res.data.manualdate,
detail:res.data.detail,
imagesrc:res.data.detail,
shipping:res.data.shipping,
entercash:entercash,
indicator:'1'//to print payment change on invoice



      }
      
     //setPbalance(res.data.pbalance);
      setPrintData(printDatas);
      setEntercash(0);
      setShipping(0);
    
        setIsPrint(true);
setLoading(false);
setPbalance(parseFloat((parseFloat(res.data.grandtotal)-parseFloat(res.data.discount)+parseFloat(res.data.salestax)-(parseFloat(res.data.payment)+parseFloat(res.data.recovery))+parseFloat(res.data.pbalance)+parseFloat(res.data.shipping))).toFixed(2));

if(holdIndex!==''){
  const customerData = [...holdData];
  customerData.splice(holdIndex, 1);
  
  setHoldData(customerData);

 
  localStorage.setItem('customerData',JSON.stringify(customerData));

}
 
      });
    }else{

      toast.warn('Fillup All Fields', {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false);
    }


},[extra_discount,discount,grandTotal,holdData,holdIndex,item,manualdate,ppadvance,recovery,salesTax,salesTaxAmount,salesmanId,selectedOptionsector,selectedOptionshop,shipping,shopId,type,userId,entercash]);
useEffect(()=>{


  if(isPrint){

 
    var div = document.getElementById('printablearea');

    // Create a window object.
    var win = window.open('', '', 'height=700,width=700'); // Open the window. Its a popup window.
    win.document.write(div.outerHTML);     // Write contents in the new window.
    win.document.close();
    setTimeout(function() {
      win.print();
      
    }, 2000);
  


  setIsPrint(false);
  setItem([]);
  }

},[isPrint]);


/*
const handleSalesTax=(e)=>{
e.preventDefault();
setSalesTax(e.target.value);

}*/
const handleManualdate=(e)=>{
e.preventDefault();
setManualdate(e.target.value);

}

  useEffect(() => {
 
 
    if(decideCustomer==='1'){
      const afterdiscount=parseFloat(pBlanace)+parseFloat(grandTotal)-parseFloat(entercash)-parseFloat(discount);
const assign=parseFloat(pBlanace)+parseFloat(grandTotal)-parseFloat(discount)+parseFloat(shipping)+parseFloat(salesTaxAmount);
      setRemaining(parseFloat(parseFloat(afterdiscount).toFixed(2)+parseFloat(shipping)+parseFloat(salesTaxAmount).toFixed(2)).toFixed(2));
      setPPadvance(parseFloat(assign).toFixed(2));
    setRecovery(0);
    setBalance(afterdiscount.toFixed(2));
    
    
    }else{

    
if(parseFloat(entercash) > (parseFloat(grandTotal)-parseFloat(discount)+parseFloat(shipping)+parseFloat(salesTaxAmount))){
  const afterdiscount=parseFloat(pBlanace)+parseFloat(grandTotal)-parseFloat(entercash)-parseFloat(discount);
 
  setRemaining(0);
  setPPadvance((parseFloat(grandTotal)-parseFloat(discount)+parseFloat(shipping)+parseFloat(salesTaxAmount)).toFixed(2));
setRecovery((parseFloat(entercash)-(parseFloat(grandTotal)-parseFloat(discount)+parseFloat(shipping)+parseFloat(salesTaxAmount))).toFixed(2));
setBalance(afterdiscount.toFixed(2));
}else{
  const afterdiscount=parseFloat(pBlanace)+parseFloat(grandTotal)-parseFloat(entercash)-parseFloat(discount);
 
  setRemaining(parseFloat(parseFloat(afterdiscount).toFixed(2)+parseFloat(shipping)+parseFloat(salesTaxAmount).toFixed(2)).toFixed(2));
  setPPadvance(parseFloat(entercash));
setRecovery(0);
setBalance(afterdiscount.toFixed(2));

}}
setSalesTaxAmount(parseFloat((parseFloat(grandTotal)+parseFloat(shipping))*salesTax/100).toFixed(2));

  },[decideCustomer,entercash,pBlanace,discount,grandTotal,salesTax,shipping,salesTaxAmount]);


  const handleEntercash = (e) => {
    e.preventDefault();
    
    if (e.target.value === '') {
      setEntercash(0);
    } else {
     setEntercash(e.target.value);
    }
  };
  



const handleDiscount=(e)=>{
  e.preventDefault();
  if(e.target.value===''){
    setDsicount(0);
    setfix_discount(0);
  }else{
    if(e.target.value>(parseFloat(grandTotal)+parseFloat(shipping)+parseFloat(salesTaxAmount)))
   {
    toast.error('Discount is greater than total', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });


setDsicount(0);
setfix_discount(0);

   }else{
    setDsicount(e.target.value);
setfix_discount(e.target.value-extra_discount);


   }

  }
  
  } 

 const handleKeyDown = (event) => {

  if (event.key === "Enter") {
    if(autocompleteRef.current){
      autocompleteRef.current.focus();

    }
  }

};



useEffect(() => {
    const calculations = item.reduce(
      (acc, item) => {
        //const extraDiscount = parseInt(item.extra_discount * item.qty);
        const qty = parseInt(item.qty);
        const pcs = parseInt(item.pcs);
        const packQty = parseInt(item.pack_qty);
        const weight = parseFloat(item.weight * item.qty);
        const unitSalePrice = parseFloat(item.unitsaleprice);
        const grandTotal = parseFloat(item.unitsaleprice * item.qty);
        const subtotalDiscount = parseFloat(item.subtotaldiscount);

        // Accumulate values only if they are valid
       // acc.extraDiscount += isNaN(extraDiscount) ? 0 : extraDiscount;
        acc.qty += isNaN(qty) ? 0 : qty;
        acc.pcs += isNaN(pcs) ? 0 : pcs;
        acc.ctn += isNaN(packQty) ? 0 : packQty;
        acc.weight += isNaN(weight) ? 0 : weight;
        acc.unitSalePrice += isNaN(unitSalePrice) ? 0 : unitSalePrice;
        acc.grandTotal += isNaN(grandTotal) ? 0 : grandTotal;
        acc.totalDiscount += isNaN(subtotalDiscount) ? 0 : subtotalDiscount;

        return acc;
      },
      {
       // extraDiscount: 0,
        qty: 0,
        pcs: 0,
        ctn: 0,
        weight: 0,
        unitSalePrice: 0,
        grandTotal: 0,
        totalDiscount: 0,
      }
    );

    // Update the state based on the accumulated results
    setextra_discount(calculations.totalDiscount);
    setTotalQty(calculations.qty);
    setTotalPcs(calculations.pcs);
    setTotalCtn(Math.floor(calculations.ctn));
    setTotalWeight(calculations.weight.toFixed(2));
    setTotalUnitSalePrice(calculations.unitSalePrice.toFixed(2));
    setGrandTotal(calculations.grandTotal.toFixed(2));
    setDsicount((calculations.totalDiscount).toFixed(2));
setfix_discount(calculations.extraDiscount);
  }, [item]);

/*
  useEffect(() => {

    const extra_discount = item.reduce((acc, item) => {
      const value = parseInt(item.extra_discount*item.qty);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    setextra_discount(extra_discount);

    const qty = item.reduce((acc, item) => {
      const value = parseInt(item.qty);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    setTotalQty(qty);
    const pcs = item.reduce((acc, item) => {
      const value = parseInt(item.pcs);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    setTotalPcs(pcs);

    const ctn = item.reduce((acc, item) => {
      const value = parseInt(item.pack_qty);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    setTotalCtn(Math.floor(ctn));

    const weight = item.reduce((acc, item) => {
      const value = parseFloat(item.weight*item.qty);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    setTotalWeight(weight.toFixed(2));
    const unitSalePrice = item.reduce((acc, item) => {
      const value = parseFloat(item.unitsaleprice);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    setTotalUnitSalePrice(unitSalePrice.toFixed(2));

    const sumgrandtotal = item.reduce((acc, item) => {
      const value = parseFloat(item.unitsaleprice*item.qty);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    setGrandTotal(sumgrandtotal.toFixed(2));

    const totaldiscount = item.reduce((acc, item) => {
      const value = parseFloat(item.subtotaldiscount);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    setDsicount((totaldiscount+extra_discount).toFixed(2));

  }, [item]);
*/
  
  useEffect(() => {
 
    if (inputRef.current) {
      
      inputRef.current.focus();
      inputRef.current.select();

    }
  },[item.length]);
 
   
  useEffect(() => {
    const handleKeyDown = (event) => {
      
      if (event.ctrlKey && event.keyCode === 66) { // ctrl+b key combination
        setIsColHidden(!isColHidden);
      }if (event.ctrlKey && event.keyCode === 32) { // ctrl+space to print invoice
        handleSaveReciept(event);
      }
      if (event.ctrlKey && event.keyCode === 67) { // ctrl+c key jump to take cash
        inputRefCash.current.focus();
      }
      
    };
  
    document.addEventListener("keydown", handleKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isColHidden,item,handleSaveReciept]);
  
  
     
      
  async function handleSubmit(val) {
    try {
      
     // const {data: {item: newItem}} = await axios.get(`/api/fetchitem/${val.id}`);
   
      const newItem = results.find(item => item.id === val.id);

      const itemExists = item.find(({id}) => id === newItem.id);
      
      if (itemExists) {
        const updatedItems = item.map((existingItem) =>
        existingItem.id === newItem.id
          ? { ...existingItem, qty: existingItem.qty + 1 }
          : existingItem
      );

      setItem(updatedItems);

      setSelectedOption(null);
       
        toast.warning("Item Qty Added !", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

      } else {
        const remqty = {
          'remaining': newItem.qty,
          'discountpercent': newItem.extra_discount?newItem.extra_discount:0,
          'subtotaldiscount': newItem.extra_discount?newItem.extra_discount:0,
         
        };
        
        setItem([
          ...item,
          {...newItem, qty: 1,pack_qty:0,pcs:0,unitsaleprice:(isCheck==='1'?newItem.unitsaleprice:isCheck==='2'?newItem.unitretailprice:newItem.unitwholesaleprice), ...remqty}
        ]);
       
        setSelectedOption(null);
      }
    } catch (error) {
      console.error(error);
    }
  }
  
         
          
    



const handleDiscountPercent = useCallback((id, event) => {
  const newItems = [...item];
  const index = newItems.findIndex((item) => item.id === id);
  newItems[index].discountpercent = event.target.value;
//below is a code for percentage disount 
/*
  const subtotaldis =
    (newItems[index].qty * newItems[index].unitsaleprice * event.target.value) / 100;
  
    newItems[index].subtotaldiscount=subtotaldis;*/
    //this is for salman traders formula
    
    const subtotaldis=event.target.value*newItems[index].qty;
    newItems[index].subtotaldiscount = parseFloat(subtotaldis).toFixed(2);
  newItems[index].grandtotal = parseFloat(
    newItems[index].qty * newItems[index].unitsaleprice - subtotaldis
  ).toFixed(2);



  setItem(newItems);
}, [item, setItem]);



const handleSubTotalDiscount=useCallback((id,event)=>{
  
  const newItems = [...item];
  const index = newItems.findIndex(item => item.id === id);
newItems[index].subtotaldiscount=event.target.value;
const discountvalue=event.target.value;
const total=parseFloat(newItems[index].qty*newItems[index].unitsaleprice).toFixed(2);
const disval=total-discountvalue;
const disper=(((total-disval)/total)*100).toFixed(2);
newItems[index].discountpercent=disper;
newItems[index].grandtotal=parseFloat((newItems[index].qty*newItems[index].unitsaleprice)-(discountvalue)).toFixed(2);

setItem(newItems);  

  
}, [item, setItem]);

const handlePackChange = useCallback((id, event) => {
  
  setItem(prevItems => {
    const index = prevItems.findIndex(item => item.id === id);
const newQty=Math.floor(event.target.value===''?0:parseFloat(event.target.value*prevItems[index].pack_size)+(prevItems[index].pcs));
    if (newQty <0 ) {
      alert('less than 1 not allowed');
      return prevItems;
    }

    const newItem = {
      ...prevItems[index],
      qty: newQty,
      pack_qty:event.target.value,
      //commented code for discount percentage
    //  subtotaldiscount: parseFloat((newQty * prevItems[index].unitsaleprice) * prevItems[index].discountpercent / 100).toFixed(2),
    subtotaldiscount: parseFloat((newQty * prevItems[index].discountpercent) ).toFixed(2),
    extrasubtotaldiscount: parseFloat((newQty * prevItems[index].discountpercent) ).toFixed(2),
      grandtotal: parseFloat((newQty * prevItems[index].unitsaleprice) - (newQty * prevItems[index].unitsaleprice) * prevItems[index].discountpercent / 100).toFixed(2)
    
    };

    return [...prevItems.slice(0, index), newItem,...prevItems.slice(index + 1)];
  });
}, [setItem]);


const handlePiecesChange = useCallback((id, event) => {
  
  setItem(prevItems => {
    const index = prevItems.findIndex(item => item.id === id);
const newQty=(event.target.value===''?0+(Math.floor(prevItems[index].pack_qty*prevItems[index].pack_size)):parseFloat(event.target.value)+(Math.floor(prevItems[index].pack_qty*prevItems[index].pack_size)));
const pack_qty=Math.floor(newQty/prevItems[index].pack_size);
const pcs=newQty%prevItems[index].pack_size;  

if (newQty <0 ) {
      alert('less than 1 not allowed');
      return prevItems;
    }

    const newItem = {
      ...prevItems[index],
      qty: newQty,
      pcs:pcs,
      pack_qty:pack_qty,
      //commented code for discount percentage
    //  subtotaldiscount: parseFloat((newQty * prevItems[index].unitsaleprice) * prevItems[index].discountpercent / 100).toFixed(2),
      subtotaldiscount: parseFloat((newQty * prevItems[index].discountpercent) ).toFixed(2),
      grandtotal: parseFloat((newQty * prevItems[index].unitsaleprice) - (newQty * prevItems[index].unitsaleprice) * prevItems[index].discountpercent / 100).toFixed(2)
    
    };

    return [...prevItems.slice(0, index), newItem,...prevItems.slice(index + 1)];
  });
}, [setItem]);

const handleQtyChange = useCallback((id, event) => {
  setItem(prevItems => {
    const index = prevItems.findIndex(item => item.id === id);
    const newQty = parseFloat(event.target.value===''?0:event.target.value);
const pack_qty=Math.floor(newQty/prevItems[index].pack_size);
const pcs=newQty%prevItems[index].pack_size;
    if (newQty <0 ) {
      alert('less than 1 not allowed');
      return prevItems;
    }

    const newItem = {
      ...prevItems[index],
      qty: newQty,
      pack_qty:pack_qty,
      pcs:pcs,
      
     
      //commented code for discount percentage
    //  subtotaldiscount: parseFloat((newQty * prevItems[index].unitsaleprice) * prevItems[index].discountpercent / 100).toFixed(2),
    subtotaldiscount: parseFloat((newQty * prevItems[index].discountpercent) ).toFixed(2),
      grandtotal: parseFloat((newQty * prevItems[index].unitsaleprice) - (newQty * prevItems[index].unitsaleprice) * prevItems[index].discountpercent / 100).toFixed(2)
    };

    return [...prevItems.slice(0, index), newItem,...prevItems.slice(index + 1)];
  });
}, [setItem]);

  
  
    const handleUnitSalePriceChange = useCallback((id, event) => {
      const newItems = [...item];
      const index = newItems.findIndex(item => item.id === id);
      newItems[index].unitsaleprice = event.target.value;
      //these two lines for percent disocunt
      //just comment these two lines for salman trader formula
      //const subtotaldis=(newItems[index].qty*newItems[index].unitsaleprice)*newItems[index].discountpercent/100;
      const subtotaldis=(newItems[index].qty*newItems[index].discountpercent);
      newItems[index].subtotaldiscount=parseFloat(subtotaldis).toFixed(2);
      


      setItem(newItems);
    },[item,setItem]);
   
    useEffect(() => {
     
      axios.get('/sanctum/csrf-cookie')
        .then(response => {
          Promise.all([
            axios.get(`/api/getsalesman`),
            axios.get(`/api/getsectors`),
          
            axios.get(`/api/getautoinvoicesnames`)
          ])
            .then(responses => {
              const [salesmanRes, sectorsRes, autoInvoicesRes] = responses;
              setSalesmanId(salesmanRes.data.salesman[0]['id']);
              setsalesman(salesmanRes.data.salesman);
              setsectors(sectorsRes.data.sectors);
              setSelectedOptionsector(sectorsRes.data.sectors[0]['id']);
              setAutoInvoices(autoInvoicesRes.data.names);

            })
            .catch(error => {
              console.error(error);
            });
        })
        .catch(error => {
          console.error(error);
        });
    }, []);
   
    useEffect(()=>{
      setLoading(true);
      axios.get(`/api/getproducts/${id}`).then(res=>{

        setresutls(res.data.skills);

        setLoading(false);
      })
    },[id])

const handleSalesmanid=(e)=>{

e.preventDefault();
setSalesmanId(e.target.value);


  }

  useEffect(() => {
setLoading(true);
    
    const data={
      id:shopId,
      salesman_id:salesmanId
      }
      
      if(shopId>0){
        axios.post('/api/shopkeeper/pbalance',data).then(res=>{
          setPbalance(res.data);
          setLoading(false);
          });
      }else{

        setPbalance(0);
      }
     

  }, [salesmanId,shopId])

const bringUserModel=(e)=>{
e.preventDefault();

setIsAddUser(true);


}

const fetchPbalance=(event)=>{
event.preventDefault();

  setShopId(event.target.value);






}


  

  useEffect(() => {
    if (selectedOptionsector) {
      
      axios.get(`/api/getsubsectors/${selectedOptionsector}`)
      .then(response => {
        setsubsectors(response.data.subsectors);
        setSelectedOptionshop(response.data.subsectors[0]['id']);
        
      })
      .catch(error => {
        console.error(error);
      });
    }
   
  },[selectedOptionsector]);










  const memoizedItems = useMemo(() => item, [item]);

  const handleRemove = (id) => {
  //setLoading(true);
    setItem((prevItems) => {
      const filteredItems = memoizedItems.filter((item) => item.id !== id);
      return filteredItems;
    });
    //setLoading(false);
  
  };
  
  

const fetchAutoInvoiceSubmit=(e)=>{
e.preventDefault();
const data={
  id:e.target.value
}
if(e.target.value){
  axios.post('/api/fetch/auto/invoice',data).then(res=>{
   
   // setItem(res.data.item);
   
    const updatedItems = res.data.item.map(item => {
      return {...item,qty:1, remaining:item.qty,discountpercent:0,subtotaldiscount:0}; // Add a new 'price' attribute to each item
    });
    
   
   setItem(updatedItems);
  
  });
   
}else{
  alert('no data found');
}


}
 
const handleFilterCart=(e)=>{

  const { value } = e.target;
    

    const items = document.querySelectorAll(".itembody");
  
   //const name = item.querySelector("[name='name']").value.toLowerCase()
    items.forEach((item) => {
      const itemText = item.textContent.toLowerCase();
      const shouldShowItem = itemText.indexOf(value) > -1;
      item.style.display = shouldShowItem ? "flex" : "none";
    });


    


}


  return (
    <div className='row fadeInDown animated' style={{padding:'4px'}}>
 

    {!isColHidden && (   
<div className='col-md-3 shadows'>
<div className='col-md-12 addCustomerBoxes'>
{type==='Salesman'?
<select disabled  className='form-control' value={userId} onChange={(e)=>handleSalesmanid(e)}>
<option>Select Salesman</option>

{salesman.map(({id,name})=>(

<option  key={id} value={id}>{name}</option>
))}
</select>
:
<select className='form-control' value={salesmanId} onChange={(e)=>handleSalesmanid(e)}>
<option>Select Salesman</option>

{salesman.map(({id,name})=>(

<option key={id} value={id}>{name}</option>
))}
</select>
}


</div>

<div className='col-md-12 col-sm-12 addCustomerBoxes'>

<select className='form-control'  value={selectedOptionsector} onChange={e => setSelectedOptionsector(e.target.value)}>
<option  >Select Region</option>
{sectors.map(({id,name})=>(

<option key={id} value={id} >{name}</option>
))}
</select>




</div>
<div className='col-md-12 col-sm-12 addCustomerBoxes'>

<select className='form-control' value={selectedOptionshop} onChange={e => setSelectedOptionshop(e.target.value)}>
<option  >Select City</option>

{subsectors && subsectors.map(item => (
  <option key={item.id} value={item.id}>
    {item.name}
  </option>
))}
  
</select>
</div>

<div className='col-md-12 col-sm-12 addCustomerBoxes' style={{display:'flex'}}>
<div className='col-md-10 col-sm-6 addCustomerBoxes'>
<select className='form-control' value={shopId}  onChange={fetchPbalance}>


{shop && shop.map(item => (
  <option key={item.user_id} value={item.user_id}>
    {item.name}
  </option>
))}
</select>

</div>

<div className='col-md-2 col-sm-6 addCustomerBoxes'>
<Fab size="small" color="primary" aria-label="add" onClick={bringUserModel}>
<AddIcon/>
</Fab>

</div>



</div>
<div style={{marginTop:'10px',display:'flex',justifyContent:'space-around'}}>
<div className="radio-label">
        <input
          type="radio"
          name="radio-groups"
          value="1"
          checked={decideCustomer === '1'}
          onChange={handleCreditCash}
        />
        <label>Cash Buyer</label>
      </div>
      <div className="radio-label">
        <input
          type="radio"
          name="radio-groups"
          value="2"
          disabled={type === 'Salesman'}
          checked={decideCustomer === '2'}
          onChange={handleCreditCash}
        />
        <label>Credit Buyer</label>
      </div>
   
  </div>

<div className='col-md-12 col-sm-12 addCustomerBoxes'>

<input type="date" className='form-control'  value={manualdate} name='manualdate' onChange={handleManualdate}></input>
</div>
<div className='col-md-12 col-sm-12 addCustomerBoxes'>

<select className='form-control' onChange={fetchAutoInvoiceSubmit}>
<option>AutoInvoice</option>


{autoInvoices && autoInvoices.map(item => (
  <option key={item.id} value={item.id}>
    {item.name}
  </option>
))}
</select>
</div>
<div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
<div className="radio-label">
        <input
          type="radio"
          name="radio-group"
          value="1"
          checked={isCheck === "1"}
          onChange={handleRadioChange}
        />
        <label>Retail</label>
      </div>
     
      
        <div className="radio-label">
          <input
            type="radio"
            name="radio-group"
            value="2"
            disabled={type === 'Salesman'}
            checked={isCheck === "2"}
            onChange={handleRadioChange}
          />
          <label>Sale</label>
        </div>
      <div className="radio-label">
        <input
          type="radio"
          name="radio-group"
          value="3"
          disabled={type === 'Salesman'}
          checked={isCheck === "3"}
          onChange={handleRadioChange}
        />
        <label>Wholesale</label>
      </div>
  </div>




  <div className='row recentinvoice' style={{ position: 'relative',overflowY:'scroll', height: '280px' }}>
  {holdData.map((item, index) => (
    <div  key={index} style={{cursor:'pointer'}} onClick={()=>handleHoldBack(index)}>
    <Alert
     
      severity="info"
      variant="filled" 
      onClose={(e) => {handleDeleteOnHold(e,index)}}
      style={{ marginTop: '5px', position: 'absolute', top: `${index * 50 }px`,width:'90%',left:'25px',backgroundColor:'255,255,255,0.5' }}
    >
      Invoice No {index + 1} on hold
    </Alert></div>
  ))}

  <center>
    {shopId === 0 ? (
      <Puff style={{ width: '100px', height: '100px' }} stroke='red' />
    ) : (
      <img
        style={{ width: '100px', height: '100px', borderRadius: '50px', marginTop: '10px' }}
        alt="sorry"
        src='https://azizks.posreact.com/softwareapis/images/final.jpg'
      ></img>
    )}
  </center>

  <h4>Aziz Super Store</h4>
  <p>Invoice No=<span>{printData.invoiceno}</span></p>
  <p>Date&Time=<span>{printData.manualdate}</span></p>
  <hr></hr>
  <p>Total=<span>{parseFloat(parseFloat(printData.grandtotal)+parseFloat(printData.shipping)+parseFloat(printData.salestax)).toFixed(2)}</span></p>
  <hr></hr>
  <div className='col-5'>
    <p>Name:<span>{printData.invoiceno && printData.customer.name}</span></p>
  </div>
  <div className='col-7'>
    <p>Phone:<span>{printData.invoiceno && printData.customer.phone}</span></p>
  </div>
  

</div>




</div>
    )}


<div className={isColHidden ? "col-md-12 shadowsforspecial" : "col-md-9 shadows"} >

<div className='row addSearchBoxes'>
<Autocomplete
  disablePortal
  id="combo-box-demo"
  value={selectedOption}
  onChange={(event, newValue) => {
    setSelectedOption(newValue);
    handleSubmit(newValue);
  }}
  autoHighlight
  getOptionLabel={(results) => `${results.name} (${results.alias})`}
  isOptionEqualToValue={(option, value) =>
    (option && option.name) === (value && value.name) ||
    (option && option.alias) === (value && value.alias)
  }
  options={results}
  noOptionsText={"No Item Found"}
  renderOption={(props, results) => (
    <Box component="li" {...props} key={results.id}>
      <img loading="lazy" src={`${weburl}${results.image}`} alt='' width={30} height={30}></img> <p style={{marginLeft:'4px'}}>{results.name}</p>
    </Box>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label={selectedOption ? <Puff stroke="red" /> : 'Search Item'}
      variant="outlined"
      className="form-control"
      inputRef={autocompleteRef}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && params.inputProps.value) {
          const inputText = params.inputProps.value.toLowerCase();
          const matchingOptions = results.filter((option) =>
            (option.name || '').toLowerCase().indexOf(inputText) !== -1 ||
            (option.alias || '').toLowerCase().indexOf(inputText) !== -1
          );

          // Get the currently highlighted option
          const highlightedOption = matchingOptions.find((option) => params.getOptionLabel(option) === params.inputProps.value);

          if (highlightedOption) {
            setSelectedOption(highlightedOption);
            handleSubmit(highlightedOption.id);
          }
        }
      }}
    />
  )}
/>



</div>
<Row className='g-1 cartBarHeading' style={{paddingRight:'2px',marginTop:'4px',borderRadius:'4px'}}>
<div className='col-2'>

<input className='form-control' placeholder='search' onKeyUp={(e)=>handleFilterCart(e)}></input>
</div>
<div className='col-1'>
Cotton
</div><div className='col-1' style={{textAlign:'center'}}>
Pieces
</div>
<div className='col-1' >
Total Qty
</div>
<div className='col-1'>
Weight
</div>
<div className='col-1'>
Rem
</div>
<div className='col-1'>
Rate
</div>
<div className='col-2'>
Discount & Total
</div>
<div className='col-1'>
Total
</div>
<div className='col-1'>
Remove
</div>
</Row>
<div className='cartBody'>
  


 
{item && item.map((items, index)=> (
  <Row className='g-1  mt-1 ml-2 animated fadeInDown itembody' key={items.id}>
 <span style={{display:'none'}}>{items.name}</span>
 <div className='col-2' >
 
<TextField
          label="Name"
       
          value={items.name}
         name='name'
        title={parseFloat(items.unitcostprice).toFixed(2)}
          size="small"
        />
 </div>

 <div className='col-1'>
 
 
<TextField
          
        
          onKeyDown={(e)=>handleKeyDown(e)} 
         label={"Ctn"}
         name='pack_qty'
          size="small"
          type='number'
          
          value={items.pack_qty ? Math.floor(items.pack_qty) : ''} 
          onChange={event => handlePackChange(items.id, event)}
        />

 </div>

 <div className='col-1'>
 
 
 <TextField
           
         
           onKeyDown={(e)=>handleKeyDown(e)} 
          label={"("+items.unit+")"}
          name='pcs'
           size="small"
           type='number'
           
             value={items.pcs?items.pcs:''}
           onChange={event => handlePiecesChange(items.id, event)}
         />
 
  </div>
 

 <div className='col-1'>
 
 
<TextField
          label={"Total "+items.unit}
          inputRef={index === item.length - 1 ? inputRef : null}
          onKeyDown={(e)=>handleKeyDown(e)} 
         
         name='qty'
          size="small"
          type='number'
          step="any"
          value={items.qty?items.qty:''}  
          onChange={event => handleQtyChange(items.id, event)}
        />

 </div>
 <div className='col-1' >

<TextField
          label="weight"
        
          
         
         name='weight'
          size="small"
          type='number'
          value={(items.weight*items.qty).toFixed(2)}  
          onChange={event => handleQtyChange(items.id, event)}
          disabled
        />
 </div>
 <div className='col-1'>
 <TextField
          label="Remaining"
        
        
         
         name='remaining'
          size="small"
          type='number'
          value={parseFloat(items.remaining).toFixed(2)}  
         
          disabled
        />

 </div>
 <div className='col-1'>
 
 
 <TextField
          label="Rate"
        
        
         
         name='rate'
          size="small"
          type='number'
         
          onKeyDown={(e)=>handleKeyDown(e)}  
           value={items.unitsaleprice} 
           onChange={event => handleUnitSalePriceChange(items.id, event)}
        />
 
 </div>
<div className='col-md-1'>


<TextField
          label="Discount"
        
        
         
         name='discount'
          size="small"
          type='number'
         
          onKeyDown={(e)=>handleKeyDown(e)}  
          value={items.discountpercent}
          onChange={event => handleDiscountPercent(items.id, event)} 
        />
</div>
<div className='col-md-1'>
<TextField
          label="Total"
        disabled
        //activate it when you work for percentage discount
         
         name='subtotaldiscount'
          size="small"
          type='number'
         
          onKeyDown={(e)=>handleKeyDown(e)}  
          value={items.subtotaldiscount} 
          onChange={event => handleSubTotalDiscount(items.id, event)}
        />
</div>


 <div className='col-1'>

 <TextField
          label="Grand Total"
        
        
         
       
          size="small"
          type='number'
         
          value= {(items.qty*items.unitsaleprice).toFixed(2)}
  disabled
        />
 </div>
 <div className='col-1' style={{borderBottom:'1px solid lightgray'}}>
 
<FaTrashAlt onClick={(e) => handleRemove(items.id)} style={{cursor:'pointer',color:'blue',fontSize:'30px'}}></FaTrashAlt>
 </div> 
 </Row>

))}

</div>
<div className='row cartBodyTotal' >
<div className='col-2'>
Total

</div>
<div className='col-1'>
{totalCtn}
</div>
<div className='col-1'>
{totalPcs}

</div>
<div className='col-1'>
{totalQty}
</div>
<div className='col-1'>
{totalWeight}
</div>
<div className='col-1'>

</div>
<div className='col-1'>
{totalUnitSalePrice}
</div>
<div className='col-1'>

</div>
<div className='col-1'>
{extra_discount.toFixed(2)}
</div>
<div className='col-1' >
  <b>{grandTotal}</b>

</div>

</div>


<div className='row cartButtonsRow1'>

<div className='col-1'>
Discount:
</div>
<div className='col-2'>
<input className='form-control' type='number' name='discount' value={discount!==0?discount:''} onChange={handleDiscount}></input>
</div>
<div className='col-2' style={{display:'none'}}>
Shipping
</div>
<div className='col-2' style={{display:'none'}}>
<input className='form-control'    value={shipping!==0?shipping:''}  onChange={(e)=>handleShipping(e)}></input>
</div>

<div className='col-1' >
Fix.Dis
</div>
<div className='col-2'>
<input className='form-control'    value={extra_discount?extra_discount.toFixed(2):''}  disabled></input>
</div>
<div className='col-1' >
Ext.Dis
</div>
<div className='col-2'>
<input className='form-control'  style={{border:'2px solid red'}}   value={fix_discount?fix_discount.toFixed(2):'0'}  disabled></input>
</div>
<div className='col-1'>
GST/HST:
</div>
<div className='col-2'>
<input className='form-control'    value={salesTax!==0?salesTax:''} title='After Updating Value Press Enter'  onKeyDown={handleKeyPress} onChange={(e)=>setSalesTaxInLocalStorage(e)}></input>
</div>


</div>
<div className='row cartButtonsRow2'>
<div className='col-2'>
Paid Amount:
</div>
<div className='col-2'>
<input className='form-control' name='ppadvance' disabled value={ppadvance} ></input>
</div>
<div className='col-2'>
Enter Cash:
</div>
<div className='col-2'>
<input className='form-control' ref={inputRefCash} type='number' name='entercash' value={entercash!==0?entercash:''} onChange={(e)=>handleEntercash(e)}></input>
</div>
<div className='col-2'>
Recovery:
</div>
<div className='col-2'>
<input className='form-control' name='recovery' disabled value={recovery}></input>
</div>
    
</div>



<div className='row cartButtonsRow4'>
<div className='col-3'>
Pre Balance=<span>{parseFloat(pBlanace).toFixed(2)}</span>
</div>



<div className='col-2'>
GST/HST=<span>{parseFloat(salesTaxAmount).toFixed(2)}</span>
</div>
<div className='col-2'>
Amount=<span>{parseFloat(parseFloat(remaining)+parseFloat(salesTaxAmount)+parseFloat(shipping)-parseFloat(pBlanace)).toFixed(2)}</span>
</div>
<div className='col-2'>
Shipping=<span>{shipping}</span>
</div>

<div className='col-3' style={{position:'relative',textAlign:'start'}}>
Balance=<h1 style={{position:'absolute',top:'-10px',left:'30%',size:'45px',color:'yellow',direction: 'ltr'}}>{(parseFloat(salesTaxAmount)+parseFloat(balance)+parseFloat(shipping)).toFixed(2)}</h1>
</div>



    
</div>

<div className='row cartButtonsRow5'>
<div className='col-3'>


<Button className=" btn-lg bt-block" variant='success' onClick={(e)=>handleSaveReciept(e)} disabled={loading} >
     
     <FaPrint className='mb-1' style={{color:'yellow'}}/> {loading? 'Please...':''}
     {loading? <Puff stroke="#98ff98" height={26} width={30}   speed={.75}  />:'Save & Print'} 
    </Button>
    
</div>
<div className='col-3'>
<Button className=" btn-lg bt-block" variant='danger' onClick={handleOnHold} disabled={holdLoading} >
     
     <FaRecycle className='mb-1' style={{color:'white'}}/> {holdLoading? 'Please...':''}
     {holdLoading? <Puff stroke="#98ff98" height={26} width={30}   speed={.75}  />:'Hold Order'} 
    </Button>

</div>





<div className='col-3 '>
<Button className=" btn-lg bt-block" variant='info'  onClick={(e)=>HandleResetValues(e)}>
     <FaPrint className='mb-1' style={{color:'red'}}/> Reset Values
    </Button>
</div>
<div className='col-3'>
  <Link to="/">
  <Button className=" btn-lg bt-block" variant='warning'  >
     <FaDashcube className='mb-1' style={{color:'blue'}}/>Dashboard 
    </Button>
</Link>

</div>
    
</div>

</div>
<div style={{ display: 'none' }} >
{
  
  isPrint  && <PrintSaleInvoice data={printData} />
  
  
  }


{
  
 isAddUser && <AddUserModel  show={isAddUser} onChildSubmit={handleAddNewUser} onChildClose={handleModelClose} />
  
  
  }

 
 
</div>


    </div>
  )
}

export default FreshSaleEntry
